var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=helpers.helperMissing, alias4="function";

  return "        <div class=\"list-group-item \" style=\"\n             border-width: thin;\n            border-color: lightgrey;\">\n            <div class=\"list-view-pf-main-info\">\n                <div class=\"list-view-pf-left\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.logo : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"list-view-pf-body\">\n                    <div class=\"list-view-pf-description\" style=\"width: 70%\">\n                        <div class=\"list-group-item-heading\">\n                            <center>\n                                <a href=\"c/"
    + alias2(container.lambda((depths[1] != null ? depths[1].community : depths[1]), depth0))
    + "/q/"
    + alias2(((helper = (helper = helpers.slug || (depth0 != null ? depth0.slug : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"slug","hash":{},"data":data}) : helper)))
    + "\">\n                                    <h4 class=\"title-overflow\">\n                                        "
    + alias2(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n                                    </h4>\n                                </a>\n                            </center>\n                        </div>\n                        <!-- Section 0 -->\n                        <div class=\"list-group-item-text\">\n                            <div class=\"opentext-list\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.long_description : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </div>\n\n                    <!-- Section 1-->\n                    <div class=\"list-view-pf-additional-info\" style=\"width: 30%\">\n                        <div class=\"list-view-pf-additional-info-item\" style=\"width: 100%;\">\n                            <div class=\"col-xs-6 col-sm-6\">\n                                <div class=\"text-center\" style=\"padding-top: 1em\">\n                                    <h5><i class=\"fas fa-fw fa-list\" style=\"font-size:1.5em;\"></i></h5>\n                                    <h5>"
    + alias2(((helper = (helper = helpers.fingerprints_count || (depth0 != null ? depth0.fingerprints_count : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"fingerprints_count","hash":{},"data":data}) : helper)))
    + "</h5>\n                                </div>\n                            </div>\n                            <div class=\"col-xs-6 col-sm-6\">\n                                <div class=\"text-center\" style=\"padding-top: 1em\">\n                                    <h5><i class=\"fas fa-fw fa-question\" style=\"font-size:1.5em;\"></i></h5>\n                                    <h5>"
    + alias2(((helper = (helper = helpers.questions_count || (depth0 != null ? depth0.questions_count : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"questions_count","hash":{},"data":data}) : helper)))
    + "</h5>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        <img src=\""
    + container.escapeExpression(((helper = (helper = helpers.logo || (depth0 != null ? depth0.logo : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"logo","hash":{},"data":data}) : helper)))
    + "\" style=\"min-width:10em;width:10em;\">\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                        <img src=\"/static/upload_images/nopic.gif\" style=\"min-width:10em;width:10em;\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                                    "
    + container.escapeExpression(((helper = (helper = helpers.long_description || (depth0 != null ? depth0.long_description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"long_description","hash":{},"data":data}) : helper)))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                                    There is no description for this questionnaire.\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<div class=\"col-md-12 col-sm-12 col-lg-12\">\n    <h3>Questionnaires</h3>\n</div>\n\n<!-- List -->\n<div class=\"container-fluid\">\n    <div id=\"pf-list-standard\" class=\"list-group list-view-pf\" style=\"box-shadow: none\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.questionnaires : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true,"useDepths":true});