var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "    <div class=\"col-sm-6 col-md-3\">\n        <div class=\"panel panel-default\">\n            <div class=\"panel-body comm_container\">\n                <div class=\"comm_header\">\n                    <center>\n                        <h4 class=\"title-overflow\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.logo : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </center>\n                </div>\n\n                <div class=\"row\">\n                    <div class=\"col-xs-6 col-sm-6\">\n                        <div class=\"text-center\" style=\"padding-top: 1em\">\n                            <h5><i class=\"fas fa-fw fa-list\" style=\"font-size:1.5em;\"></i></h5>\n                            <h5>"
    + alias4(((helper = (helper = helpers.fingerprints_count || (depth0 != null ? depth0.fingerprints_count : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fingerprints_count","hash":{},"data":data}) : helper)))
    + "</h6>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6 col-sm-6\">\n                        <div class=\"text-center\" style=\"padding-top: 1em\">\n                            <h5><i class=\"fas fa-fw fa-question\" style=\"font-size:1.5em;\"></i></h5>\n                            <h5>"
    + alias4(((helper = (helper = helpers.questions_count || (depth0 != null ? depth0.questions_count : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questions_count","hash":{},"data":data}) : helper)))
    + "</h5>\n                        </div>\n                    </div>\n                </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.logo : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                        <div class=\"community_description\" style=\"word-break: break-word;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.long_description : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                        </div>\n                        <a class=\"desc_shower pull-right\">&nbsp;</a>\n\n                    </h4>\n                    <div class=\"comm_footer\">\n                        <div class=\"row\">\n                            <div class=\"col-md-12\">\n                                <a data-status=\"0\"\n                                    onclick=\"bootbox.dialog({title: '"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.community : depth0)) != null ? stack1.name : stack1), depth0))
    + "', message: '"
    + alias4(((helper = (helper = helpers.more_message || (depth0 != null ? depth0.more_message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"more_message","hash":{},"data":data}) : helper)))
    + "', buttons: { close: { label: 'Close', className: 'btn-default', callback: function(){ this.modal('hide'); } }}})\"\n                                    class=\" pull-right\" href=\"javascript:void(0)\">More</a>\n                            </div>\n                        </div>\n\n                        <center> <a href=\"c/"
    + alias4(alias5((depths[1] != null ? depths[1].community : depths[1]), depth0))
    + "/q/"
    + alias4(((helper = (helper = helpers.slug || (depth0 != null ? depth0.slug : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-blue btn-panel-body\"><svg\n                                    class=\"svg-inline--fa fa-folder-open fa-w-18 fa-fw\" aria-hidden=\"true\"\n                                    focusable=\"false\" data-prefix=\"fas\" data-icon=\"folder-open\" role=\"img\"\n                                    xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 576 512\" data-fa-i2svg=\"\">\n                                    <path fill=\"currentColor\"\n                                        d=\"M572.694 292.093L500.27 416.248A63.997 63.997 0 0 1 444.989 448H45.025c-18.523 0-30.064-20.093-20.731-36.093l72.424-124.155A64 64 0 0 1 152 256h399.964c18.523 0 30.064 20.093 20.73 36.093zM152 224h328v-48c0-26.51-21.49-48-48-48H272l-64-64H48C21.49 64 0 85.49 0 112v278.046l69.077-118.418C86.214 242.25 117.989 224 152 224z\">\n                                    </path>\n                                </svg><!-- <i class=\"fas fa-fw fa-folder-open\"></i> -->&nbsp; OPEN</a>\n\n                        </center>\n\n                    </div>\n            </div>\n        </div>\n    </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        <img src=\""
    + container.escapeExpression(((helper = (helper = helpers.logo || (depth0 != null ? depth0.logo : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"logo","hash":{},"data":data}) : helper)))
    + "\" class=\"community_logo\" style=\"height: 100px;\">\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <h4 class=\"desc_container\" style=\"height: 90px;\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                    <h4 class=\"desc_container\" style=\"height: 180px;\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                            "
    + container.escapeExpression(((helper = (helper = helpers.long_description || (depth0 != null ? depth0.long_description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"long_description","hash":{},"data":data}) : helper)))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                            There is no description for this questionnaire.\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<div class=\"col-md-12 col-sm-12 col-lg-12\">\n    <h3>Questionnaires</h3>\n</div>\n\n<!-- Cards -->\n<div class=\"row\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.questionnaires : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});