import {Controller} from 'stimulus';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import 'datatables.net-bs/js/dataTables.bootstrap.js';
import 'jstree';
import 'jstree/dist/themes/default/style.min.css';


function handleIcons(json, valid_tags){

    function tagParent(uri){
        if (uri === "#")
            return
        for (let i = 0; i < json.length; i++)
            if (uri === json[i].id) {
                if (!valid_tags.hasOwnProperty(json[i].id))
                    json[i]["icon"] = "fas fa-folder-plus"
                tagParent(json[i]["parent"])
            }
    }

    for (let i = 0; i < json.length; i++)
        if (valid_tags.hasOwnProperty(json[i].id)){
            if (json[i]["icon"] === "far fa-file"){
                json[i]["icon"] = "fas fa-file-alt"
            }
            else{
                json[i]["icon"] = "fas fa-folder"
            }
            tagParent(json[i]["parent"])
        }

    return json
}


export default class extends Controller {
    static targets = ['ontologyTree', 'questionnaireTree', 'usedClassesTree', 'resultsTable', 'ontologySelect', 'ontologySmallMessage', 'questionsValidTags'];

    initialize() {
        this.loadOntology();
    }

    search() {
        const tags = $(this.ontologyTreeTarget).jstree('get_selected');
        const ques = this.data.get("questionnaireId")
        const comm = this.data.get("communityId")

        fetch('api/semantic-search?' + $.param({'tag': tags, 'q': ques, 'c': comm}))
            .then(resp => resp.json())
            .then(json => {
                $(this.resultsTableTarget).DataTable({
                    'destroy': true,
                    'data': json,
                    'columns': [
                        {data: 'data', title: 'Data'},
                        {
                            data: 'fingerprint_url',
                            title: 'Fingerprint',
                            render: (data, type, row, meta) => {
                                if (type === 'display') {
                                    data = `<a href="${data}">${row.fingerprint_name}</a>`;
                                }
                                return data;
                            }
                        },
                        {data: 'question', title: 'Question'},
                    ]
                });

                const qsets = JSON.parse(this.data.get("questionSets"))
                const valid_tags = JSON.parse(this.data.get("questionsValidTags"))
                var questions = valid_tags[tags[0]]

                let result = []
                for (let i = 0; i < questions.length; i++)
                    for (let j = 0; j < qsets.length; j++)
                        if (qsets[j]["id"] === questions[i]["parent"] &&
                            !result.includes(qsets[j])
                        )
                        {
                            result.push(qsets[j])
                            break
                        }

                result = result.concat(questions)

                $(this.questionnaireTreeTarget)
                    .jstree("destroy")
                    .empty()
                $(this.questionnaireTreeTarget).jstree({
                    'conditionalselect' : function () {
                        return false
                    },
                    'core': {
                        'data': result,
                        'multiple': false,
                    },
                    "plugins" : [ "conditionalselect" ]
                })
                    .bind("ready.jstree", function () {
                        $(this).jstree("open_all");
                    });

            });
    }


    loadOntology() {
        fetch(`api/questionnaires/${this.data.get('questionnaireSlug')}`)
            .then(resp => resp.json())
            .then(json => {
                let name = ""
                if (json['main_ontology'] === "")
                    name = $(this.ontologySelectTarget).find(":selected").text()
                else
                    name = json['main_ontology'];
                fetch(`api/ontologies/${name}/`)
                    .then(resp => {
                        if (!resp.ok) {
                            $(this.ontologySmallMessageTarget).text(`Ontology '${name}' has no data associated!`)
                            $(this.ontologyTreeTarget).jstree("destroy").empty()
                        }
                        return resp;
                    })
                    .then(resp => resp.json())
                    .then(json => {
                        const valid_tags = JSON.parse(this.data.get("questionsValidTags"))
                        $(this.ontologySmallMessageTarget).text("")

                        json = handleIcons(json, valid_tags)

                        $(this.ontologyTreeTarget).jstree({
                            'conditionalselect' : function (node) {
                                let curr_icon = node['icon']
                                return curr_icon === "fas fa-file-alt" || curr_icon === "fas fa-folder";
                            },
                            'core': {
                                'data': json,
                                'multiple': false,
                            },
                            "plugins" : [ "sort", "conditionalselect" ]
                        });
                    });
            });
    }

    updateActiveOntology() {
        const name = $(this.ontologySelectTarget).find(":selected").text();
        fetch(`api/ontologies/${name}/`)
            .then(resp => {
                if (!resp.ok) {
                    $(this.ontologySmallMessageTarget).text(`Ontology '${name}' has no data associated!`)
                    $(this.ontologyTreeTarget).jstree("destroy").empty()
                }
                return resp;
            })
            .then(resp => resp.json())
            .then(json => {
                const valid_tags = JSON.parse(this.data.get("questionsValidTags"))
                $(this.ontologySmallMessageTarget).text("")

                json = handleIcons(json, valid_tags)

                $(this.ontologyTreeTarget).jstree("destroy").empty()
                $(this.ontologyTreeTarget).jstree({
                    'conditionalselect' : function (node) {
                        let curr_icon = node['icon']
                        return curr_icon === "fas fa-file-alt" || curr_icon === "fas fa-folder";
                    },
                    'core': {
                        'data': json,
                        'multiple': false,
                    },
                    "plugins" : [ "sort", "conditionalselect" ]
                });
            });
    }
}
